<!--
* 创建人：邓国伦
* 日 期：
* 描 述：换房操作记录
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">寝室调整记录</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <el-input v-model="keyword" placeholder="学号/考生号/姓名/房间号/操作人" size="small"></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button size="small" icon="el-icon-refresh" @click="reload()"></el-button>
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="cz" label="操作" width="200"> </el-table-column>
        <el-table-column prop="ksh" label="考生号" width="150"> </el-table-column>
        <el-table-column prop="xh" label="学号" width="120"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="100"> </el-table-column>
        <el-table-column prop="yfjbh" label="原房间号" width="100"> </el-table-column>
        <el-table-column prop="xfjbh" label="新房间号" width="100"> </el-table-column>
        <el-table-column prop="czr" label="操作人" width="100"> </el-table-column>
        <el-table-column prop="czsj" label="操作时间" width="180"> </el-table-column>
        <el-table-column prop="bz" label="备注"> </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import sg from "../../api/gygl";
import { getAuthorizeButtonColumnList } from "../../api/system";
export default {
  data() {
    return {
      active: 0,
      pagination: {
        rows: 10,
        page: 1,
        sord: "DESC",
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: "",
      keyValue: "",
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
    };
  },
  components: {},
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns;
      } else {
        this.buttonauth = {};
      }
    });
    this.getDataPageList();
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true;
      } else {
        return false;
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val;
      this.getDataPageList();
    },
    reload() {
      this.$router.go(0);
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = "";
      } else {
        this.keyValue = currentRow.id;
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true;
      sg.getfjtzjlpagelist({
        queryJson: JSON.stringify({ keyword: this.keyword }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false;
            this.pagination.page = res.data.page;
            this.pagination.total = res.data.total;
            this.pagination.records = res.data.records;
            this.dataPageList = res.data.rows;
          } else {
            this.tableloading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
</style>
